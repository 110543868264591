import { FleetTabs } from "@/lib/enum/fleet-tabs.enum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import VueRouter, { RawLocation } from "vue-router";
import { PartnerEventTabEnum } from "../enum/partner-events-tabs.enum";
import { VehicleTabs } from "../enum/vehicle-tabs.enum";

export class BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {}

  protected goTo(name: string, params: any, newTab: boolean) {
    if (!newTab) {
      return this.router.push({
        name,
        params
      });
    }

    const routeData = this.router.resolve({
      name,
      params
    });
    return window.open(routeData.href, "_blank");
  }

  go(to: RawLocation, newTab = false) {
    if (!newTab) {
      return this.router.push(to);
    }

    const routeData = this.router.resolve(to);
    return window.open(routeData.href, "_blank");
  }

  setUrl(to: RawLocation) {
    const resolved = this.router.resolve(to);
    history.pushState({}, "null", resolved.href);
  }
}

export class GoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  goToCredential(partnerId: string, newTab = false) {
    return this.goTo("CredentialTableView", { partnerId }, newTab);
  }

  goToPartnerUser(partnerId: string, newTab = false) {
    return this.goTo("PartnerUserList", { partnerId }, newTab);
  }

  goToPartnerEvents(partnerId: string, tab: PartnerEventTabEnum, newTab = false) {
    switch (tab) {
      case PartnerEventTabEnum.OVERDUE:
        return this.goTo("PartnerEventsOverdue", { partnerId }, newTab);
      case PartnerEventTabEnum.LIST:
        return this.goTo("PartnerEventsList", { partnerId }, newTab);
      case PartnerEventTabEnum.RECURRING:
        return this.goTo("PartnerEventsRecurring", { partnerId }, newTab);
    }
  }

  goToPartnerEventsQuery(partnerId: string, tab: PartnerEventTabEnum, vehicleId?: string, newTab = false) {
    const location: RawLocation = {
      name: "PartnerEventsOverdue",
      params: { partnerId }
    };

    if (vehicleId) {
      location.query = { vehicleId };
    }

    switch (tab) {
      case PartnerEventTabEnum.OVERDUE:
        location.name = "PartnerEventsOverdue";
        return this.go(location, newTab);
      case PartnerEventTabEnum.LIST:
        location.name = "PartnerEventsList";
        return this.go(location, newTab);
      case PartnerEventTabEnum.RECURRING:
        location.name = "PartnerEventsRecurring";
        return this.go(location, newTab);
    }
  }

  goToTicketTable(partnerId: string, newTab = false) {
    return this.goTo("TicketTable", { partnerId }, newTab);
  }

  goToTicketDetailSideCard(partnerId: string, ticketNumber?: number, newTab = false) {
    return this.goTo("TicketDetail", { partnerId, ticketNumber }, newTab);
  }

  goToCustomFieldList(partnerId: string, newTab = false) {
    return this.goTo("CustomFieldList", { partnerId }, newTab);
  }

  goToCustomFieldDetail(partnerId: string, customFieldId: string, newTab = false) {
    return this.goTo("CustomFieldDetailView", { partnerId, customFieldId }, newTab);
  }

  goToThgDetail(partnerId: string, thgId: string, newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgQuoteDetailView",
        params: {
          partnerId: partnerId,
          thgId: thgId
        }
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgQuoteDetailView",
      params: { partnerId, thgId }
    });
    window.open(routeData.href, "_blank");
  }

  goToThgAdminList(newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgAdmin"
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgAdmin"
    });
    window.open(routeData.href, "_blank");
  }

  goToChargingStations(newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgChargingStation"
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgChargingStation"
    });
    window.open(routeData.href, "_blank");
  }

  goToBillings(newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgBillingListView"
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgBillingListView"
    });
    window.open(routeData.href, "_blank");
  }

  goToMeterReadings(newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgMeterReading"
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgMeterReading"
    });
    window.open(routeData.href, "_blank");
  }

  goToMeterReadingDetail(partnerId: string, meterReadingId: string, newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgMeterReadingDetailView",
        params: {
          partnerId,
          meterReadingId
        }
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgMeterReadingDetailView",
      params: { partnerId, meterReadingId }
    });
    window.open(routeData.href, "_blank");
  }

  goToChargingStationDetail(chargingstationId: string, newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "ThgChargingStationDetailView",
        params: {
          chargingStationId: chargingstationId
        }
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "ThgChargingStationDetailView",
      params: {
        chargingStationId: chargingstationId
      }
    });
    window.open(routeData.href, "_blank");
  }

  goToPartnerDetail(partnerId: string, newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "PartnerDetailView",
        params: { partnerId: partnerId }
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "PartnerDetailView",
      params: { partnerId: partnerId }
    });
    window.open(routeData.href, "_blank");
  }

  goToUserDetail(userId: string, newTab = false) {
    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name: "UserAdministrationDetail",
        params: { userId: userId }
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name: "UserAdministrationDetail",
      params: { userId: userId }
    });
    window.open(routeData.href, "_blank");
  }

  goToOperationTable(newTab = false) {
    const to: RawLocation = {
      name: "ThgOperations"
    };
    this.go(to, newTab);
  }

  goToOperationDetail(operationId: string, partnerId?: string, newTab = false) {
    let params: { operationId: string } | { operationId: string; partnerId: string } = { operationId };
    if (partnerId) {
      params = { ...params, partnerId: partnerId };
    }

    if (!newTab) {
      return this.router.push({
        name: "OperationPartnerView",
        params: params
      });
    }

    const routeData = this.router.resolve({
      name: "OperationPartnerView",
      params: params
    });
    return window.open(routeData.href, "_blank");
  }

  async goToSignatureRequestDetail(signRequestId: string, partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "SignRequestDetailView",
        params: { partnerId, signRequestId }
      });
    }

    const routeData = this.router.resolve({
      name: "SignRequestDetailView",
      params: { partnerId, signRequestId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToFleet(partnerId: string, tab?: FleetTabs, newTab = false) {
    let name = "FleetHome";
    let params: any = { partnerId };
    if (tab) {
      name = "FleetHomeTab";
      params = { ...params, tab };
    }

    await this.goTo(name, params, newTab);
  }

  async goToVehicleDetail(vehicleId: string, partnerId: string, tab?: VehicleTabs, newTab = false) {
    let name = "FleetVehicleDetail";
    let params: any = { partnerId, vehicleId };
    if (tab) {
      name = "FleetVehicleDetailTab";
      params = { ...params, tab };
    }

    await this.goTo(name, params, newTab);
  }

  async goToEventDetail(partnerId: string, eventId: string, newTab = false) {
    await this.goTo(
      "EventDetailView",
      {
        partnerId,
        eventId
      },
      newTab
    );
  }

  async goToEventDetailEdit(partnerId: string, eventId: string, newTab = false) {
    await this.goTo(
      "EventFormView",
      {
        partnerId,
        eventId
      },
      newTab
    );
  }

  async goToContractDetail(partnerId: string, vehicleId: string, contractId: string, newTab = false) {
    const name = "FleetContractDetailView";
    const params: any = { partnerId, vehicleId, contractId };

    await this.goTo(name, params, newTab);
  }

  async goToContractDetailDetail(partnerId: string, vehicleId: string, contractId: string, newTab = false) {
    const name = "FleetContractFormView";
    const params: any = { partnerId, vehicleId, contractId };

    await this.goTo(name, params, newTab);
  }

  async goToDocumentOverview(tab?: BackendResourceEnum.SIGN | BackendResourceEnum.DOCUMENT, newTab = false) {
    if (tab) {
      await this.goTo("DigitalSignatureHomeViewTab", { tab }, newTab);

      return;
    }

    await this.goTo("DigitalSignatureHomeView", {}, newTab);
  }

  async goToBookingSetting(newTab = false) {
    await this.goTo("BookingSettingView", {}, newTab);
  }

  async goToBookingView(newTab = false) {
    await this.goTo("BookingView", {}, newTab);
  }

  async goToDocumentDetail(documentId: string, partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "DocumentDetailView",
        params: { partnerId, documentId }
      });
    }

    const routeData = this.router.resolve({
      name: "DocumentDetailView",
      params: { partnerId, documentId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToReportDetail(reportId: string, partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "PartnerReportsDetailView",
        params: { partnerId, reportId }
      });
    }

    const routeData = this.router.resolve({
      name: "PartnerReportsDetailView",
      params: { partnerId, reportId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToSharedContentAccessDetail(partnerId: string, sharedContentId: string, accessToken: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "PartnerSharedContentAccessDetail",
        params: { partnerId, sharedContentId, accessToken }
      });
    }

    const routeData = this.router.resolve({
      name: "PartnerSharedContentAccessDetail",
      params: { partnerId, sharedContentId, accessToken }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToBooking(partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "BookingView",
        params: { partnerId }
      });
    }

    const routeData = this.router.resolve({
      name: "BookingView",
      params: { partnerId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToBookingDetail(partnerId: string, bookingId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "BookingPortalDetailView",
        params: { partnerId, bookingId }
      });
    }

    const routeData = this.router.resolve({
      name: "BookingPortalDetailView",
      params: { partnerId, bookingId }
    });
    return window.open(routeData.href, "_blank");
  }

  goToBookingEdit(partnerId: string, bookingId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "BookingPortalDetailViewEdit",
        params: { partnerId, bookingId }
      });
    }

    const routeData = this.router.resolve({
      name: "BookingPortalDetailViewEdit",
      params: { partnerId, bookingId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToResourceTable(partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "ResourceTable",
        params: { partnerId }
      });
    }

    const routeData = this.router.resolve({
      name: "ResourceTable",
      params: { partnerId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToResourceDetail(partnerId: string, resourceId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "ResourceDetail",
        params: { partnerId, resourceId }
      });
    }

    const routeData = this.router.resolve({
      name: "ResourceDetail",
      params: { partnerId, resourceId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToServiceTable(partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "ServiceTable",
        params: { partnerId }
      });
    }

    const routeData = this.router.resolve({
      name: "ServiceTable",
      params: { partnerId }
    });

    return window.open(routeData.href, "_blank");
  }

  async goToReportList(partnerId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "PartnerReports",
        params: { partnerId }
      });
    }

    const routeData = this.router.resolve({
      name: "PartnerReports",
      params: { partnerId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToServiceDetail(partnerId: string, serviceId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "ServiceDetail",
        params: { partnerId, serviceId }
      });
    }

    const routeData = this.router.resolve({
      name: "ServiceDetail",
      params: { partnerId, serviceId }
    });
    return window.open(routeData.href, "_blank");
  }

  goToBillingDetail(billingNumber: string | number, partnerId?: string, newTab = false) {
    let name = "ThgBillingNumberDetailView";
    let params: any = { billingNumber };
    if (partnerId) {
      name = "ThgBillingNumberPartnerDetailView";
      params = { billingNumber, partnerId };
    }

    if (!newTab) {
      // opens in same  tab
      return this.router.push({
        name,
        params
      });
    }

    // opens in a new tab
    const routeData = this.router.resolve({
      name,
      params
    });
    window.open(routeData.href, "_blank");
  }
}
