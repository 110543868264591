/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { getArrayedNestedPath } from "@/lib/objectPath-helper";
import { ILocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.interface";
import { PaginationFilterListElement } from "@/lib/utility/data/page-filter-list-element.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { IStore } from "@/lib/utility/data/store.interface";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import Fuse from "fuse.js";
import { Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

/**
 * A base store implementation for given type T of an entity using @see `IStore`
 */
export abstract class BaseStore<T extends object, MapKeys extends string = string> extends VuexModule
  implements IStore<T> {
  /**
   * The fuzzy search threshold
   */
  static DEFAULT_FUZZY_THRESHOLD = 0.3;

  /**
   * @inheritdoc
   */
  protected abstract _data: ILocalDataAccessLayer<T>;

  /**
   * @inheritdoc
   */
  private _search: string | undefined = undefined;

  get maps() {
    return this._data.maps;
  }

  get search() {
    return this._search;
  }

  /**
   * @inheritdoc
   */
  filters: IPageFilterElement[] = [];

  /**
   * @inheritdoc
   */
  hiddenFilter: IPageFilterElement[] = [];

  /**
   * @inheritdoc
   */
  filterOptions: PaginationFilterListElement[] = [];

  /**
   * @inheritdoc
   */
  get entities() {
    return this._data.entities;
  }

  /**
   * @inheritdoc
   */
  get filtered() {
    // slice elements to avoid that the _data.entities arrays are modified at any point outside the store
    let elements = this._data.entities.slice();

    if (!this.search && !this.filters?.length && !this.hiddenFilter?.length && !this.filterOptions?.length) {
      return elements;
    }

    if (this.search) {
      const fused = new Fuse(this._data.entities, {
        keys: this.filterOptions.map(e => e.key.split("?")[0]),
        threshold: BaseStore.DEFAULT_FUZZY_THRESHOLD
      }).search(this.search);

      elements = fused.map(el => {
        return el.item;
      });
    }

    if (!this.filters?.length && !this.hiddenFilter?.length) {
      return elements;
    }

    return elements.filter(element => {
      const filter = [...this.filters, ...this.hiddenFilter];

      if (!filter.length) {
        return true;
      }

      return filter.every(filter => {
        const filterType = filter.type as PageFilterTypes;
        const filterPath = filter.key;
        const filterSpecifier = filter.specifier;

        let values: (string | boolean | number)[] = [];
        if (
          [
            PageFilterTypes.CUSTOM_FIELD_VALUE,
            PageFilterTypes.CUSTOM_FIELD_NUMBER_VALUE,
            PageFilterTypes.CUSTOM_FIELD_DATE_VALUE
          ].includes(filterType) &&
          filterSpecifier
        ) {
          /**
           * Special case for custom field filtering.
           * Look up the custom field. It is nested "one level above value" in an element of the array there.
           */
          const pathBits = filterPath.split(".");
          const pathEnd = pathBits.pop();
          const reducedPath = pathBits.join(".");
          const valuesAtReducedPath = getArrayedNestedPath(element, reducedPath);
          const customField = valuesAtReducedPath.find(f => JSON.stringify(f).includes(filterSpecifier));

          if (!customField || !pathEnd) {
            return false;
          }

          // get values from custom field
          values = getArrayedNestedPath(customField, pathEnd);
        } else {
          values = getArrayedNestedPath(element, filterPath);
        }

        let filterValue: string | boolean = filter.value;
        if ([PageFilterTypes.CUSTOM_FIELD_DATE_VALUE, PageFilterTypes.DATE].includes(filterType)) {
          filterValue = new Date(filterValue).toISOString().split("T")[0];
          values = values.map(v => new Date(v as string).toISOString().split("T")[0]);
        }

        if ([PageFilterTypes.BOOLEAN].includes(filterType)) {
          if (filterValue === "true") filterValue = true;
          if (filterValue === "false") filterValue = false;
        }

        if (!filter.operation) {
          filter.operation = PageFilterOperationEnum.EQUAL;
        }

        return values.some(v => {
          const objectValue = v;

          if (filter.operation === "$eq") {
            // use weak comparison to allow for implicit type conversion (e.g. to compare "7" with 7)
            return objectValue == filterValue;
          } else if (filter.operation === "$ne") {
            // use weak comparison
            return objectValue != filterValue;
          } else if (filter.operation === "$gt") {
            return objectValue > filterValue;
          } else if (filter.operation === "$lt") {
            return objectValue < filterValue;
          } else if (filter.operation === "$gte") {
            return objectValue >= filterValue;
          } else if (filter.operation === "$lte") {
            return objectValue <= filterValue;
          } else {
            return false;
          }
        });
      });
    });
  }

  get filteredAndSorted() {
    return this.filtered.sort((a, b) => (this._data.getIdentifier(a) > this._data.getIdentifier(b) ? -1 : 1));
  }

  /**
   * @inheritdoc
   */
  @Action
  setFilters(filters: IPageFilterElement[]) {
    this.filters.splice(0, this.filters.length, ...filters);

    return this;
  }

  /**
   * @inheritdoc
   */
  @Action
  setHiddenFilters(hiddenFilters: IPageFilterElement[]) {
    this.hiddenFilter.splice(0, this.hiddenFilter.length, ...hiddenFilters);

    return this;
  }

  /**
   * @inheritdoc
   * @deprecated use setFilters
   */
  @Action
  setFilter(filters: IPageFilterElement[]) {
    this.context.dispatch("setFilters", filters);
  }

  /**
   * @inheritdoc
   * @deprecated use setHiddenFilter
   */
  @Action
  setHiddenFilter(hiddenFilters: IPageFilterElement[]) {
    this.context.dispatch("setHiddenFilters", hiddenFilters);

    return this;
  }

  /**
   * @inheritdoc
   */
  @Action
  setSearch(search: string | undefined) {
    this.context.commit("_mutateSearch", search);
  }

  /**
   * Mutates the vuex property
   */
  @Mutation
  private _mutateSearch(search: string | undefined) {
    this._search = search;
  }

  @Action
  reset() {
    this._data.clear();
  }
}
